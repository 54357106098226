import React from "react";
import logo from "../../assets/bam.png";
import { navigate } from "gatsby";
import oktaImg from "../../assets/northstar/okta.png";
import { genAuthorizationUrl } from "../../services/okta";

const NorthstarLogin: React.FC = () => {
  return (
    <div className="flex flex-1 w-full mt-18 bg-blend-multiply bg-blue-900 bg-cover bg-no-repeat">
      <div className="bg-white m-auto">
        <div className="p-12 md:px-16 xl:px-20">
          <div className="py-4 md:py-8">
            <h1 className="text-2xl sm:text-3xl md:text-4xl uppercase text-center mb-4 font-semibold mr-2 mt-4 text-blue-900">
              Applied Insights
            </h1>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center mt-4 border border-transparent border-t-gray-300 py-4 md:py-6 lg:pt-8">
              <div className="mx-auto text-base sm:text-lg text-gray-800">
                Login Via Single Sign-On
              </div>
            </div>
            <div className="flex justify-center mt-0.5 lg:mt-1.5">
              <button
                className="w-40 xs:w-48 sm:w-60 border border-gray-300 hover:bg-gray-100 px-5 py-4 sm:px-8 sm:py-6 lg:px-10 lg:py-8"
                onClick={async () => {
                  const url = await genAuthorizationUrl();
                  navigate(url.href);
                }}
              >
                <div className="sr-only">Login via Okta</div>
                <img
                  src={oktaImg}
                  alt="Okta Logo"
                  className="w-full h-auto"
                ></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NorthstarLogin;
